import { Card } from './Card';
import { UpcomingAppointmentsTable } from './upcomingAppointments/UpcomingAppointmentsTable';
import { UpcomingAppointmentDesktop } from './upcomingAppointments/UpcomingAppointmentDesktop';
import { UpcomingAppointmentMobile } from './upcomingAppointments/UpcomingAppointmentMobile';
import { useMyAppointmentsLocale } from '../pages/MyAppointments';
import {
  UpcomingAppoinment,
  UpcomingAppointmentsDayFilter,
} from '../interfaces';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { UpcomingAppointmentsCardRightComponent } from './upcomingAppointments/UpcomingAppointmentsCardRightComponent';
import { interpolateVariables } from 'utilities/functions';
import {
  AppointmentTypeEnum,
  UpcomingAppointmentCounts,
  useGetAppointmentsByPatientCodexIdQuery,
  useGetAppointmentsByProviderQuery,
} from 'graphql/generated/remote-schema-hasura';

import {
  FHIR_APPOINTMENT_STATUS,
  ORDER_BY_DIRECTION_NUMERIC,
  SORT_FHIR_APPOINTMENT_BY,
} from 'utilities/interfaces';
import { AuthContext, AuthContextType } from 'auth/context/AuthContext';
import { SIGNAL_CHANNELS, useChannelSignal } from 'hooks/useChannelSignal';
import { Roles } from '../../../firebase/interfaces';
import { QueryContextType } from 'app/my-appointments/components/QueryContextType';

const INITIAL_USER_DATE = new Date(Date.now() - 30 * 60 * 1000).toISOString();
const MAX_LOAD_MORE_APPOINTMENTS = 5;

export const upcomingStatuses = [
  FHIR_APPOINTMENT_STATUS.PROPOSED,
  FHIR_APPOINTMENT_STATUS.BOOKED,
  FHIR_APPOINTMENT_STATUS.ARRIVED,
  FHIR_APPOINTMENT_STATUS.CHECKEDIN,
];

type dataType = {
  appointments: UpcomingAppoinment[];
  counts: UpcomingAppointmentCounts;
  total: number;
};
type varsType = {
  limit: number;
  dayFilter: UpcomingAppointmentsDayFilter;
};

export interface UpcomingAppointmentsContextType
  extends QueryContextType<UpcomingAppoinment, varsType> {
  loading: boolean;
  data: UpcomingAppoinment[];
  counts: UpcomingAppointmentCounts;
  total: number;
  variables: {
    limit: number;
    dayFilter: UpcomingAppointmentsDayFilter;
  };
  setVars: (vars: varsType) => void;
}

const UpcomingAppoinmentsContext =
  createContext<UpcomingAppointmentsContextType>({
    loading: false,
    appointments: [],
  } as unknown as UpcomingAppointmentsContextType);

export const useUpcomingAppointments = () => {
  const context = useContext(UpcomingAppoinmentsContext);
  if (!context?.setVars) {
    throw new Error(
      'useUpcomingAppointments must be used within a NotificationsProvider.',
    );
  }
  return context;
};

const UpcomingPatientsAppointmentsProvider: React.FC<{
  children: React.ReactNode;
  patientCodexId?: string | undefined;
}> = ({ children }) => {
  const { user: loggedUser } = useContext<AuthContextType>(AuthContext);
  const [data, setData] = useState<dataType>({
    appointments: [],
    counts: { today: 0, tomorrow: 0, week: 0, month: 0 },
    total: 0,
  });
  const [vars, setVars] = useState<varsType>({
    limit: 3,
    dayFilter: UpcomingAppointmentsDayFilter.NOFILTER,
  });

  const { loading, refetch } = useGetAppointmentsByPatientCodexIdQuery({
    variables: {
      appointmentStatus: upcomingStatuses,
      limit: vars.limit,
      offset: 0,
      from: INITIAL_USER_DATE,
      sort: SORT_FHIR_APPOINTMENT_BY.SUMMARY_DATE,
      sortDirection: ORDER_BY_DIRECTION_NUMERIC.ASC,
      tzOffset: new Date().getTimezoneOffset(),
      dayFilter:
        vars.dayFilter === UpcomingAppointmentsDayFilter.NOFILTER
          ? undefined
          : vars.dayFilter,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getFHIRAppointmentByRequestPatientCodexId.total > 0) {
        const allUpcommingAppointments: UpcomingAppoinment[] =
          data.getFHIRAppointmentByRequestPatientCodexId.appointment.map(
            (appointment) => ({
              appointmentId: appointment.appointmentCodexId,
              date: appointment.start || '',
              end: appointment.end || '',
              patientId: loggedUser?.uuid || '',
              providerId: appointment.codexProviderId || '',
              appointmentType:
                appointment.appointmentType || AppointmentTypeEnum.Chat,
              name: `${appointment.providerFirstName} ${appointment.providerLastName}`,
              status: appointment.status as FHIR_APPOINTMENT_STATUS,
            }),
          );
        setData({
          appointments: allUpcommingAppointments,
          counts: data.getFHIRAppointmentByRequestPatientCodexId.counts,
          total: data.getFHIRAppointmentByRequestPatientCodexId.total,
        });
      } else {
        setData({
          appointments: [],
          counts: data.getFHIRAppointmentByRequestPatientCodexId.counts,
          total: 0,
        });
      }
    },
  });

  useChannelSignal(
    (first: boolean) => {
      if (!first) {
        refetch();
      }
    },
    SIGNAL_CHANNELS.APPOINTMENTS,
    loggedUser?.uuid,
  );

  const value: UpcomingAppointmentsContextType = {
    data: data.appointments,
    counts: data.counts,
    variables: vars,
    total: data.total,
    loading,
    setVars,
  };

  return (
    <UpcomingAppoinmentsContext.Provider value={value}>
      {children}
    </UpcomingAppoinmentsContext.Provider>
  );
};

const UpcomingProviderAppointmentsProvider: React.FC<{
  children: React.ReactNode;
  patientCodexId?: string | undefined;
}> = ({ children, patientCodexId }) => {
  const { user: loggedUser } = useContext<AuthContextType>(AuthContext);
  const [data, setData] = useState<dataType>({
    appointments: [],
    counts: { today: 0, tomorrow: 0, week: 0, month: 0 },
    total: 0,
  });
  const [vars, setVars] = useState<varsType>({
    limit: 3,
    dayFilter: UpcomingAppointmentsDayFilter.NOFILTER,
  });

  const { loading, refetch } = useGetAppointmentsByProviderQuery({
    variables: {
      patientCodexId: patientCodexId,
      appointmentStatus: upcomingStatuses,
      limit: vars.limit,
      offset: 0,
      from: INITIAL_USER_DATE,
      sort: SORT_FHIR_APPOINTMENT_BY.SUMMARY_DATE,
      sortDirection: ORDER_BY_DIRECTION_NUMERIC.ASC,
      tzOffset: new Date().getTimezoneOffset(),
      dayFilter:
        vars.dayFilter === UpcomingAppointmentsDayFilter.NOFILTER
          ? undefined
          : vars.dayFilter,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.getFHIRAppointmentByRequestProviderId.total > 0) {
        const allUpcommingAppointments: UpcomingAppoinment[] =
          data.getFHIRAppointmentByRequestProviderId.appointment.map(
            (appointment) => ({
              appointmentId: appointment.appointmentCodexId,
              date: new Date(appointment.start || '').toISOString(),
              end: new Date(appointment.end || '').toISOString(),
              patientId: appointment.codexPatientId,
              providerId: appointment.codexProviderId || '',
              appointmentType:
                appointment.appointmentType || AppointmentTypeEnum.Chat,
              name: `${appointment.patientFirstName} ${appointment.patientLastName}`,
              status: appointment.status as FHIR_APPOINTMENT_STATUS,
            }),
          );
        setData({
          appointments: allUpcommingAppointments,
          counts: data.getFHIRAppointmentByRequestProviderId.counts,
          total: data.getFHIRAppointmentByRequestProviderId.total,
        });
      } else {
        setData({
          appointments: [],
          counts: data.getFHIRAppointmentByRequestProviderId.counts,
          total: 0,
        });
      }
    },
  });

  useChannelSignal(
    (first: boolean) => {
      if (!first) {
        refetch();
      }
    },
    SIGNAL_CHANNELS.APPOINTMENTS,
    loggedUser?.uuid,
  );

  const value: UpcomingAppointmentsContextType = {
    data: data.appointments,
    counts: data.counts,
    variables: vars,
    total: data.total,
    loading,
    setVars,
  };

  return (
    <UpcomingAppoinmentsContext.Provider value={value}>
      {children}
    </UpcomingAppoinmentsContext.Provider>
  );
};

export const UpcomingAppointmentsProvider: React.FC<{
  children: React.ReactNode;
  patientCodexId?: string | undefined;
}> = ({ children, patientCodexId }) => {
  const { user } = useContext(AuthContext);

  const userRole = user?.role;

  const UpcomingAppointmentsProvider =
    userRole === Roles.PROVIDER
      ? UpcomingProviderAppointmentsProvider
      : UpcomingPatientsAppointmentsProvider;

  return (
    <UpcomingAppointmentsProvider patientCodexId={patientCodexId}>
      {children}
    </UpcomingAppointmentsProvider>
  );
};

export const UpcomingAppointments: React.FC<{
  patientCodexId?: string | undefined;
}> = ({ patientCodexId }) => {
  return (
    <UpcomingAppointmentsProvider patientCodexId={patientCodexId}>
      <UpcomingAppointmentsRenderer />
    </UpcomingAppointmentsProvider>
  );
};

const UpcomingAppointmentsRenderer = () => {
  const { loading, locale } = useMyAppointmentsLocale();

  const {
    data: upcomingAppointments,
    counts: upcomingAppointmentCounts,
    loading: dataLoading,
    total,
    setVars,
    variables,
  } = useUpcomingAppointments();

  const handleLoadMore = () => {
    setVars({
      limit: variables.limit + MAX_LOAD_MORE_APPOINTMENTS,
      dayFilter: variables.dayFilter,
    });
  };

  const upcomingAppointmentsDayFilterText = useMemo<
    Record<UpcomingAppointmentsDayFilter, string>
  >(() => {
    return {
      [UpcomingAppointmentsDayFilter.NOFILTER]: `${
        locale[UpcomingAppointmentsDayFilter.NOFILTER] ||
        UpcomingAppointmentsDayFilter.NOFILTER
      }`,
      [UpcomingAppointmentsDayFilter.TODAY]: `${
        locale[UpcomingAppointmentsDayFilter.TODAY] ||
        UpcomingAppointmentsDayFilter.TODAY
      } (${upcomingAppointmentCounts.today})`,
      [UpcomingAppointmentsDayFilter.TOMORROW]: `${
        locale[UpcomingAppointmentsDayFilter.TOMORROW] ||
        UpcomingAppointmentsDayFilter.TOMORROW
      } (${upcomingAppointmentCounts.tomorrow})`,
      [UpcomingAppointmentsDayFilter.MONTH]: `${
        locale[UpcomingAppointmentsDayFilter.MONTH] ||
        UpcomingAppointmentsDayFilter.MONTH
      } (${upcomingAppointmentCounts.month})`,
      [UpcomingAppointmentsDayFilter.WEEK]: `${
        locale[UpcomingAppointmentsDayFilter.WEEK] ||
        UpcomingAppointmentsDayFilter.WEEK
      } (${upcomingAppointmentCounts.week})`,
    };
  }, [
    locale,
    upcomingAppointmentCounts.month,
    upcomingAppointmentCounts.week,
    upcomingAppointmentCounts.tomorrow,
    upcomingAppointmentCounts.today,
  ]);

  const onAppointmentsForSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const selectedValue = Object.entries(
      upcomingAppointmentsDayFilterText,
    ).find((entry) => e.target.value === entry[1]);
    if (selectedValue) {
      setVars({
        ...variables,
        dayFilter: selectedValue[0] as UpcomingAppointmentsDayFilter,
      });
    }
  };

  if (!locale || loading) {
    return null;
  }

  return (
    <Card
      title={locale.upcomingAppointmentsCardTitle}
      subtitle={interpolateVariables(locale.upcomingAppointmentsSubtitle, {
        current: upcomingAppointments.length.toString(),
        total: `${total}`,
      })}
      alwaysExpanded
      hideMobileSubtitle
      borderedSubtitleDesktop
      rightComponent={
        <UpcomingAppointmentsCardRightComponent
          appointmentsForEntries={Object.values(
            upcomingAppointmentsDayFilterText,
          )}
          appointmentsForValue={
            upcomingAppointmentsDayFilterText[variables.dayFilter]
          }
          locale={locale}
          onAppointmentsForSelectChange={onAppointmentsForSelectChange}
        />
      }
    >
      {dataLoading ? (
        <>Loading...</>
      ) : upcomingAppointments.length ? (
        <UpcomingAppointmentsTable
          upcomingAppointments={upcomingAppointments}
          UpcomingAppointmentDesktopRow={UpcomingAppointmentDesktop}
          UpcomingAppointmentMobileRow={UpcomingAppointmentMobile}
          CanLoadMore={total > upcomingAppointments.length}
          totalAppointments={total}
          locale={locale}
          HandleOnLoadMore={handleLoadMore}
          isPatient
        />
      ) : (
        <>
          <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
          <div className="w-full text-med-gray font-semibold">
            {locale.noUpcomingAppointments}
          </div>
        </>
      )}
    </Card>
  );
};
