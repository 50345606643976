import { ReactComponent as PwEyeShowIcon } from 'assets/icons/pwEyeShow.svg';
import ButtonComponent from 'components/button/buttonComponent';
import { ReactComponent as CalendarIcon } from 'assets/icons/cancelCalendar.svg';
import { ReactComponent as EditBlueIcon } from 'assets/icons/edit-blue.svg';
import { ReactComponent as GoToMessageAppointmentIcon } from 'assets/icons/goToMessageAppointment.svg';
import { formatDateToCustomFormat } from 'utilities/functions';
import { useMyAppointmentsLocale } from 'app/my-appointments/pages/MyAppointments';
import { AuthContext } from 'auth/context/AuthContext';
import { useContext } from 'react';
import { Roles } from '../../../../firebase/interfaces';
import { useModalParams } from 'components/modal/useModalManager';
import { useNavigate } from 'react-router-dom';
import {
  MY_APPOINTMENTS,
  MY_APPOINTMENTS_TERMS_OF_USE,
  MY_PATIENTS,
  MY_PATIENTS_BIO_INFO,
  MY_PATIENTS_BIO_INFO_PATIENT_INFO,
  PROVIDERS_PROVIDER_BIO,
} from 'utilities/routes';
import { UpcomingAppoinment, appointmentRenderInfo } from '../../interfaces';
import { AppointmentTypeEnum } from 'graphql/generated/hasura';
import {
  MY_APPOINTMENTS_CHAT_CONSULTATION,
  MY_APPOINTMENTS_VIDEO_CONSULTATION,
} from 'utilities/routes';
import { PARAM_MODALS_IDENTIFIERS } from 'utilities/constants';
import { useIsJoinable } from 'hooks/useIsJoinable';
import { FHIR_APPOINTMENT_STATUS } from 'utilities/interfaces';

interface Props extends UpcomingAppoinment {
  isDashboardView: boolean;
  hideProvider?: boolean;
}

export const UpcomingAppointmentDesktop: React.FC<Props> = ({
  date,
  end,
  name: provider,
  providerId,
  patientId,
  appointmentType,
  hideProvider = true,
  appointmentId,
  status,
}) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const appointmentRender = appointmentRenderInfo[appointmentType];
  const AppointmentRenderIcon = appointmentRender.icon;
  const { locale } = useMyAppointmentsLocale();
  const { isOpen: isJoinAppointmentOpen } = useModalParams(
    PARAM_MODALS_IDENTIFIERS.JOIN_APPOINTMENT_MODAL,
  );

  const userRole = user?.role;
  const handleViewAppointmentDetails = () => {
    if (userRole === Roles.PROVIDER) {
      navigate(
        `.?${PARAM_MODALS_IDENTIFIERS.APPOINTMENT_DETAILS_MODAL_PROVIDER_ID}=true`,
        {
          state: { appointmentId },
        },
      );
    } else {
      navigate(
        `${MY_APPOINTMENTS}?${PARAM_MODALS_IDENTIFIERS.VIEW_APPOINTMENT_DETAILS_MODAL_PATIENT}=true`,
        {
          state: { appointmentId },
        },
      );
    }
  };
  const handleCancelAppointment = () => {
    if (userRole === Roles.PROVIDER) {
      navigate(
        `${MY_APPOINTMENTS}?${PARAM_MODALS_IDENTIFIERS.CANCEL_APPOINTMENT_MODAL_PROVIDER_ID}=true`,
        {
          state: { appointmentId },
        },
      );
    } else {
      navigate(
        `${MY_APPOINTMENTS}?${PARAM_MODALS_IDENTIFIERS.CANCEL_APPOINTMENT_MODAL}=true`,
        {
          state: { appointmentId },
        },
      );
    }
  };
  const handleJoinAppointment = () => {
    const base =
      appointmentType === AppointmentTypeEnum.Chat
        ? MY_APPOINTMENTS_CHAT_CONSULTATION
        : MY_APPOINTMENTS_VIDEO_CONSULTATION;
    navigate(`${base}&appointment-id=${appointmentId}`, {
      state: { providerId, patientId },
    });
  };
  const handleEditAppointment = () => {
    navigate(MY_APPOINTMENTS_TERMS_OF_USE, { state: { appointmentId } });
  };

  const handleMessagingAppoinment = () => {
    // we can add the email if desired: 'mailto:email@example.com?subject=Subject&body=Body%20goes%20here'
    window.open('mailto:subject=Appointment');
  };
  const navigateToProfile = () => {
    if (user?.role === Roles.PROVIDER) {
      navigate(
        `${MY_PATIENTS}/${patientId}/${MY_PATIENTS_BIO_INFO}/${MY_PATIENTS_BIO_INFO_PATIENT_INFO}`,
      );
    } else {
      navigate(`${PROVIDERS_PROVIDER_BIO}`, {
        state: { providerId },
      });
    }
  };

  let extraAppointmentAction: React.ReactNode = null;
  switch (appointmentType) {
    case AppointmentTypeEnum.Chat:
    case AppointmentTypeEnum.Video:
      extraAppointmentAction = (
        <div className="flex-1">
          <ButtonComponent
            className="max-h-[45px] w-[120px]"
            desktopFullWidth
            onClick={handleJoinAppointment}
          >
            {locale.joinNow}
          </ButtonComponent>
        </div>
      );
      break;
    case AppointmentTypeEnum.Email:
      extraAppointmentAction = (
        <GoToMessageAppointmentIcon
          className="cursor-pointer"
          onClick={handleMessagingAppoinment}
        />
      );
      break;
    default:
      extraAppointmentAction = null;
  }

  const isJoinable = useIsJoinable({ startDate: date, endDate: end, status });

  if (isJoinAppointmentOpen) {
    if (appointmentType === AppointmentTypeEnum.Video) {
      navigate(MY_APPOINTMENTS_VIDEO_CONSULTATION);
    } else {
      navigate(MY_APPOINTMENTS_CHAT_CONSULTATION);
    }
  }

  const canEdit =
    user?.role === Roles.PATIENT && status === FHIR_APPOINTMENT_STATUS.BOOKED;

  return (
    <>
      <div className="hidden desktop:flex flex-col gap-2.5 w-full pb-2.5">
        <hr className="flex flex-row w-full items-center h-px bg-black-blur" />
        <div
          className={'w-full grid grid-cols-[1fr,1fr,1fr,250px] items-center'}
        >
          <p className="text-sm text-med-gray flex-1 ">
            {formatDateToCustomFormat(date)}
          </p>
          {!hideProvider && (
            <p
              className="text-sm font-semibold flex-1 underline hover:cursor-pointer"
              onClick={navigateToProfile}
            >
              {provider}
            </p>
          )}
          <p className="text-med-gray text-base font-normal leading-snug flex-1 flex flex-row gap-2 items-center">
            <AppointmentRenderIcon className="inline" />
            {locale[appointmentRender.text]}
          </p>
          <div className="flex flex-row gap-5 flex-1 items-center justify-start">
            <PwEyeShowIcon
              className="cursor-pointer"
              onClick={handleViewAppointmentDetails}
            />
            {canEdit && (
              <EditBlueIcon
                className="cursor-pointer"
                onClick={handleEditAppointment}
              />
            )}
            <CalendarIcon
              className="cursor-pointer fill-clc-blue"
              onClick={handleCancelAppointment}
            />
            {appointmentType === AppointmentTypeEnum.Email && (
              <GoToMessageAppointmentIcon
                className="cursor-pointer"
                onClick={handleMessagingAppoinment}
              />
            )}
            {status === FHIR_APPOINTMENT_STATUS.PROPOSED && (
              <p className="text-sm text-med-gray flex-1 ">
                {locale.pedingStatus}
              </p>
            )}
            {isJoinable && extraAppointmentAction}
          </div>
        </div>
      </div>
    </>
  );
};
