import { ReactComponent as FileDownloadIcon } from 'assets/icons/file-download.svg';
import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/pwEyeShow.svg';
import { useGetFileFromStorage } from 'hooks/useGetFileFromStorage';
import { printPdf } from 'utilities/functions';
import { Scan } from 'app/dashboard/interfaces/scan.interfaces';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DASHBOARD,
  DASHBOARD_SCAN_RESULTS,
  MY_DERMSCORE_SCAN_RESULTS,
} from 'utilities/routes';
import { usePdfDownload } from 'hooks/usePdfDownload';
import { useIsMobile } from 'hooks/useIsMobile';
import { PARAM_MODALS_IDENTIFIERS } from 'utilities/constants';

interface ScanActionsProps {
  scan: Scan;
  alignIconsRight?: boolean;
  useCurrentPathNavigate?: boolean;
}

const ScanActions: React.FC<ScanActionsProps> = ({
  alignIconsRight = false,
  scan,
  useCurrentPathNavigate = false,
}) => {
  const alignIconsRightClass = alignIconsRight
    ? 'justify-end'
    : 'justify-center';

  const { fetchFileFromStorage } = useGetFileFromStorage();
  const { downloadPdf } = usePdfDownload();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();
  const isDashboard = location.pathname === DASHBOARD;
  const scanResultUrl = isDashboard
    ? DASHBOARD_SCAN_RESULTS
    : MY_DERMSCORE_SCAN_RESULTS;

  const handleOnView = async () => {
    const imageUrl = await fetchFileFromStorage(scan.imageId);
    navigate(
      useCurrentPathNavigate
        ? `.?${PARAM_MODALS_IDENTIFIERS.MY_PATIENTS_DERMSCORE_SCANS_MODAL_ID}=true`
        : scanResultUrl,
      {
        state: {
          scan: {
            ...scan,
            imageUrl,
          },
          fileUrl: imageUrl,
          onlyView: true,
        },
      },
    );
  };

  const handleOnDownload = async () => {
    scan?.reportId && downloadPdf(scan.reportId);
  };

  const handleOnPrint = async () => {
    const fileUrl = await fetchFileFromStorage(scan.reportId);
    printPdf(fileUrl);
  };

  return (
    <div
      className={`flex flex-row w-full desktop:w-auto items-center justify-center desktop:${alignIconsRightClass} p-0 gap-2.5 desktop:gap-[2px]`}
    >
      <div className="flex flex-row items-center justify-center px-2.5 gap-2.5 rounded-10">
        <EyeIcon
          onClick={handleOnView}
          className="w-[18px] h-2.5 fill-current cursor-pointer"
        />
      </div>
      <div className="flex flex-row items-center justify-center px-2.5 gap-2.5 rounded-10">
        <FileDownloadIcon
          onClick={handleOnDownload}
          className="w-[15px] h-[18px] cursor-pointer fill-clc-blue"
        />
      </div>
      {!isMobile && (
        <div className="flex flex-row items-center justify-center px-2.5 gap-2.5 rounded-10">
          <PrintIcon
            onClick={handleOnPrint}
            className="w-[17px] h-[16px] cursor-pointer fill-clc-blue"
          />
        </div>
      )}
    </div>
  );
};

export default ScanActions;
